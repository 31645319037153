import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { freeCategories, memberCategories, news } from "../components/category";
import { RefreshIcon, ChevronRightIcon } from "@heroicons/react/solid";
import Filter from "../components/filter";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

const CategoryList = ({ params, data }) => {
  memberCategories.forEach((m) => (m.type = "member"));
  freeCategories.forEach((m) => (m.type = "free"));
  news.type = "free";
  const cats = [...memberCategories, ...freeCategories, news];
  const category = params.frontmatter__category;
  const cat = cats.find((c) => c.catSlug === category);
  const catName = cat.catName;
  const catType = cat.type;

  const allNodes = data.allMarkdownRemark.nodes;
  const [nodes, setNodes] = useState(allNodes);

  const allYears = [
    ...new Set(
      allNodes
        .filter((node) => node.frontmatter.date)
        .map((node) => node.frontmatter.date.substring(0, 4))
    ),
  ];
  const [years, setYears] = useState(allYears);
  const allTags = [
    ...new Set(
      allNodes
        .filter((node) => node.frontmatter.tag)
        .map((node) => node.frontmatter.tag.toString())
    ),
  ];
  const [tags, setTags] = useState(allTags);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    if (selectedYear || selectedTag) {
      setNodes(
        allNodes.filter((node) => {
          return (
            (selectedYear
              ? node.frontmatter.date.substring(0, 4) === selectedYear
              : true) &&
            (selectedTag
              ? node.frontmatter.tag.toString() === selectedTag
              : true)
          );
        })
      );
    } else {
      setNodes(allNodes);
    }
    setYears([
      ...new Set(
        allNodes
          .filter((node) => {
            return selectedTag
              ? node.frontmatter.tag.toString() === selectedTag
              : true;
          })
          .filter((node) => node.frontmatter.date)
          .map((node) => node.frontmatter.date.substring(0, 4))
      ),
    ]);
    setTags([
      ...new Set(
        allNodes
          .filter((node) => {
            return selectedYear
              ? node.frontmatter.date.substring(0, 4) === selectedYear
              : true;
          })
          .filter((node) => node.frontmatter.tag)
          .map((node) => node.frontmatter.tag.toString())
      ),
    ]);
  }, [selectedYear, selectedTag]);

  return (
    <Layout>
      <Seo title={catName} />
      {category === "seminar" ? (
        <div className="relative flex flex-col justify-center items-center bg-cyan-50 text-gray-600 font-sans">
          <StaticImage src="../images/seminar.webp" alt={catName}></StaticImage>
          <div className="absolute top-1/2 -translate-y-1/2 text-white bg-black bg-opacity-30 w-full text-center py-5 md:py-10 px-2 md:px-5">
            <div className="text-lg md:text-4xl pb-5 md:pb-10 font-bold leading-relaxed md:leading-loose">
              ライブ配信後の人気セミナーが
              <br />
              24時間いつでも聴講できます！
            </div>
            <div className="text-xs md:text-base leading-normal md:leading-relaxed">
              ライブ配信を終えた、弊社グループチェイス・ネクスト主催の人気セミナーが、
              <br />
              24時間いつでも視聴できるようになりました。
              <br />
              中国ビジネスに携わる皆様の実務理解や法律制度の理解にお役立ていただけますと幸いです。
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center p-5 md:p-20 bg-cyan-50 text-gray-600 font-sans">
          <h1 className="md:text-2xl text-lg font-medium mb-2 text-center">
            {catName}
          </h1>
          {category !== "news" &&
            (catType === "member" ? (
              <div className="bg-cyan-500 text-white px-2 py-1">会員</div>
            ) : (
              <div className="bg-teal-500 text-white px-2 py-1">無料</div>
            ))}
        </div>
      )}
      <div className="bg-gray-100 text-gray-600">
        <nav className="container mx-auto p-1 md:p-3 font-sans">
          <ol className="list-reset flex items-center text-sm">
            <li>
              <Link to="/" className="text-blue-600 hover:text-blue-700">
                ホーム
              </Link>
            </li>
            <li className="mx-1">
              <ChevronRightIcon className="w-4 h-4" />
            </li>
            <li>{catName}</li>
          </ol>
        </nav>
      </div>
      <div className="container mx-auto max-w-5xl px-5 md:px-10 py-10 md:py-20 text-sm md:text-base">
        {category !== "seminar" && (
          <div className="mb-5 md:flex grid grid-cols-1 gap-3 justify-end items-center font-sans">
            <div className="flex gap-2">
              <p className="text-gray-600 text-sm">{`${nodes.length}件表示/${allNodes.length}件`}</p>
              {tags.length > 0 || years.length > 0 ? (
                <button
                  onClick={() => {
                    setSelectedYear(null);
                    setSelectedTag(null);
                  }}
                >
                  <RefreshIcon className="w-4 h-4 text-gray-400" />
                </button>
              ) : null}
            </div>
            {tags.length > 0 ? (
              <Filter
                selected={selectedTag}
                setSelected={setSelectedTag}
                options={tags}
                msgSelect="タグを選択"
              />
            ) : null}
            {years.length > 0 ? (
              <Filter
                selected={selectedYear}
                setSelected={setSelectedYear}
                options={years}
                msgSelect="年を選択"
              />
            ) : null}
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4">
          {category === "seminar"
            ? nodes.map((node, index) => (
                <Link
                  to={`${node.frontmatter.no}`}
                  key={index}
                  className="hover:bg-cyan-100 border border-cyan-400 block relative font-sans"
                >
                  <GatsbyImage
                    image={getImage(node.frontmatter.image)}
                    alt={node.frontmatter.title}
                  />
                  <div key={index} className="p-5">
                    <div className="mb-3">
                      {`【${String(node.frontmatter.no).padStart(3, "0")}】 ${
                        node.frontmatter.title
                      }`}
                    </div>
                    <div className="text-sm mb-2">
                      {`${new Date(
                        node.frontmatter.date
                      ).getFullYear()}年${new Date(
                        node.frontmatter.date
                      ).getMonth()+1}月 | ${node.frontmatter.length}`}
                    </div>
                    <div className="text-sm">{node.frontmatter.speaker}</div>
                  </div>
                </Link>
              ))
            : nodes.map((node, index) => (
                <Link
                  to={`${node.frontmatter.slug}`}
                  className="hover:bg-cyan-100 block font-sans p-5 border border-cyan-400"
                  key={index}
                >
                  <div className="mb-3">{node.frontmatter.title}</div>
                  <div className="mb-3 text-sm text-gray-600 flex gap-3">
                    <div className="font-number font-light">
                      {node.frontmatter.date}
                    </div>
                    {node.frontmatter.tag ? (
                      <div>{node.frontmatter.tag}</div>
                    ) : null}
                  </div>
                  <div className="text-sm">{node.excerpt}</div>
                </Link>
              ))}
        </div>
      </div>
    </Layout>
  );
};

export default CategoryList;

export const query = graphql`
  query ($frontmatter__category: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $frontmatter__category } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          title
          slug
          date
          category
          tag
          no
          speaker
          length
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        fields {
          collection
        }
        excerpt(truncate: true, pruneLength: 200)
      }
    }
  }
`;
